import { RssFeed } from './types';
import { ArrayVector, DataFrame, dateTime, FieldType } from '@grafana/data';

export function feedToDataFrame(feed: RssFeed): DataFrame {
  const date = new ArrayVector<number>([]);
  const title = new ArrayVector<string>([]);
  const link = new ArrayVector<string>([]);
  const content = new ArrayVector<string>([]);
  const description = new ArrayVector<string>([]);
  const count = new ArrayVector<string>([]);
  const dash = new ArrayVector<string>([]);
  const ogImage = new ArrayVector<string>([]);

  for (const item of feed.items) {
    const val = dateTime(item.pubDate);

    try {
      date.buffer.push(val.valueOf());
      title.buffer.push(item.title);
      link.buffer.push(item.link);
      if (item.description != null) {
        description.buffer.push(item.description);
      }
      if (item.count != null) {
        count.buffer.push(item.count);
      }
      if (item.dash != null) {
        dash.buffer.push(item.dash);
      }
      if (item.ogImage != null) {
        ogImage.buffer.push(item.ogImage);
      }

      if (item.content) {
        const body = item.content.replace(/<\/?[^>]+(>|$)/g, '');
        content.buffer.push(body);
      }
    } catch (err) {
      console.warn('Error reading integration item:', err, item);
    }
  }

  return {
    fields: [
      { name: 'date', type: FieldType.time, config: { displayName: 'Date' }, values: date },
      { name: 'title', type: FieldType.string, config: {}, values: title },
      { name: 'link', type: FieldType.string, config: {}, values: link },
      { name: 'content', type: FieldType.string, config: {}, values: content },
      { name: 'description', type: FieldType.string, config: {}, values: description },
      { name: 'count', type: FieldType.string, config: {}, values: count },
      { name: 'dash', type: FieldType.string, config: {}, values: dash },
      { name: 'ogImage', type: FieldType.string, config: {}, values: ogImage },
    ],
    length: date.length,
  };
}
